// @Vendors
import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// @Utils
import { getEntes, getFacilitadores } from 'utils/helpers';
import { GET_CATEGORIAS_CHECK } from 'state/categorias/categorias.actionsTypes';

// @Moment
import moment from 'moment';
import 'moment/locale/es';

// @Components
import AppField from 'components/Form/AppField';
import SimpleSelect from 'components/Form/SimpleSelect';
import CheckBox from './checkBox';
import Grid from '@material-ui/core/Grid';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  upload: {
    display: 'none',
  },
  image: {
    display: 'flex',
    '& img': {
      width: '100px',
      objectFit: 'cover',
      margin: '0 auto',
    },
  },
  scroll: {
    overflow: 'auto',
    overflowX: 'hidden',
  },
}));

const initial = {
  nombre: '',
  descripcion: '',
  fecha_inicio: moment().format('YYYY-MM-DD'),
  fecha_fin: moment().format('YYYY-MM-DD'),
  fecha_inicio_clases: moment().format('YYYY-MM-DD'),
  fecha_fin_clases: moment().format('YYYY-MM-DD'),
  imagen: '',
  ente_id: '',
  estatus: '',
  capacidad: '',
  categorias: [],
  facilitadores: [],
  modalidad: ''
};

export const status = [
  { label: 'activo', value: 0 },
  { label: 'no activo', value: 1 },
  { label: 'terminado', value: 2 },
  { label: 'stand By', value: 3 },
];

export const modalidades = [
  { label: 'No definido', value: 0 },
  { label: 'Presencial', value: 1 },
  { label: 'En linea', value: 2 },
]

function CursoFrom({ initialValues = initial, onSubmit, ActionsButtons = '' }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const onlyLetters = RegExp('^[a-zA-ZÁÉÍÓÚáéíóúñÑ0-9().,: ]+$');

  var isLoading = useSelector((state) => state.appReducer.get('loading'));
  const categorias = useSelector((state) =>
    state.categoriasReducer.get('categoriasCheck')
  );

  // @Validaciones
  const SignupSchema = Yup.object().shape({
    nombre: Yup.string()
      .required('¡Campo obligatorio!')
      .matches(onlyLetters, 'valor fuera de rango'),
    descripcion: Yup.string()
      .required('¡Campo obligatorio!')
      .matches(onlyLetters, 'valor fuera de rango'),
    fecha_fin: Yup.date().required('¡Campo obligatorio!'),
    fecha_inicio: Yup.date().required('¡Campo obligatorio!'),
    fecha_fin_clases: Yup.date().required('¡Campo obligatorio!'),
    fecha_inicio_clases: Yup.date().required('¡Campo obligatorio!'),
    ente_id: Yup.string().required('¡Campo obligatorio!'),
    capacidad: Yup.number().required('¡Campo obligatorio!'),
    categorias: Yup.array().required('¡Campo obligatorio!'),
    facilitadores: Yup.array(),
    estatus: Yup.number().optional(),
    sede: Yup.string()
      .optional()
      .matches(onlyLetters, 'valor fuera de rango'),
    modalidad: Yup.string().required('  Campo obligatorio!')
  });

  // States Locales
  const [img, setImg] = useState({ file: null });
  const [entes, setState] = useState([{ label: 'Cargando...', value: '' }]);
  const [stateFacilitadores, setStateFacilitadores] = useState([
    { label: 'Cargando...', value: '' },
  ]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEls, setAnchorEls] = React.useState(null);
  const [eventSedes, setEventSedes] = useState('');

  // Funciones
  const previewImg = (e, setFieldValue) => {
    setImg({ file: URL.createObjectURL(e.target.files[0]) });
    setFieldValue('imagen', e.target.files[0]);
  };

  const setingEventSedes = (e, setFieldValue) => {
    setEventSedes(e.target.value);
    setFieldValue('modalidad', e.target.value);
  };

  const handleToogle = (v, setFieldValue, values, name) => {
    let currentIndex = values.indexOf(v);

    var newArray = [...values];
    if (currentIndex === -1) {
      newArray.push(v);
    } else {
      newArray.splice(currentIndex, 1);
    }

    setFieldValue(name, newArray);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickProfesores = (event) => {
    setAnchorEls(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEls(null);
  };

  const obtenerCategorias = useCallback(() => {
    dispatch({
      type: GET_CATEGORIAS_CHECK,
    });
  }, [dispatch]);

  const obtenerFacilitadores = useCallback(async () => {
    try {
      const result = await getFacilitadores();
      var array = [];

      result.data.data.rows.forEach((e) => {
        array.push({
          label:
            e.usuarios_perfil.primer_nombre +
            ' ' +
            e.usuarios_perfil.primer_apellido,
          value: e.id,
        });
      });

      setStateFacilitadores(array);
    } catch (err) {
      //
    }
  }, [setStateFacilitadores]);

  const obtenerEntes = useCallback(async () => {
    try {
      const result = await getEntes();
      var entesArray = [];

      result.data.data.forEach((e) => {
        entesArray.push({
          label: e.nombre,
          value: e.id,
        });
      });

      setState(entesArray);
    } catch (err) {
      //
    }
  }, [setState]);

  useEffect(() => {
    obtenerEntes();
  }, [obtenerEntes]);

  useEffect(() => {
    obtenerCategorias();
  }, [obtenerCategorias]);

  useEffect(() => {
    obtenerFacilitadores();
  }, [obtenerFacilitadores]);

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={SignupSchema}>
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => 
        {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <AppField
                    name='nombre'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.nombre}
                    label='Titulo del curso (*)'
                    autoFocus={true}
                    helperText={<ErrorMessage name='nombre' />}
                    error={errors.nombre && touched.nombre}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SimpleSelect
                    style={{ minWidth: '160px' }}
                    id='modalidad'
                    name='modalidad'
                    value={values.modalidad}
                    onChange={(e) => setingEventSedes(e, setFieldValue)}
                    onBlur={handleBlur}
                    label='Modalidades (*)'
                    items={modalidades}
                    helpertext={<ErrorMessage name='modalidad' />}
                    error={errors.modalidad && touched.modalidad}
                  />
                </Grid>
		{eventSedes === 1 ? (
                <Grid item xs={12}>
                  <AppField
                    name='sede'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.sede}
                    label='Sede del curso (*)'
                    helperText={<ErrorMessage name='sede' />}
                    error={errors.sede && touched.sede}
                  />
                </Grid>
		) : null }
                <Grid item>
                  <input
                    className={classes.upload}
                    accept='image/*'
                    onChange={(e) => previewImg(e, setFieldValue)}
                    id='imagen'
                    type='file'
                    name='imagen'
                  />
                  <label htmlFor='imagen'>
                    <Button variant='contained' color='primary' component='span'>
                      Cargar imagen
                    </Button>
                  </label>
                </Grid>
                <Grid item>
                {initialValues.imagen ? (
                  <img alt="Imagen Oferta Academica Actual" src={`data:image/png;base64,${initialValues.imagen}`}/>
                ) : null}
                  {img.file && (
                    <div className={classes.image}>
                      <img src={img.file} alt='Imagen Oferta Academica Nueva' />
                    </div>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <AppField
                    name='descripcion'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.descripcion}
                    label='Descripcion'
                    helperText={<ErrorMessage name='descripcion' />}
                    error={errors.descripcion && touched.descripcion}
                    multiline
                    rows={8}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <AppField
                    type='date'
                    name='fecha_inicio'
                    value={moment(values.fecha_inicio).format('YYYY-MM-DD')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label='Fecha de inicio de preinscripciones'
                    helperText={<ErrorMessage name='fecha_inicio' />}
                    error={errors.fecha_inicio && touched.fecha_inicio}
                  />
                </Grid>

                <Grid item sm={12} md={6}>
                  <AppField
                    type='date'
                    name='fecha_fin'
                    value={moment(values.fecha_fin).format('YYYY-MM-DD')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label='Fecha de fin de preinscripciones'
                    helperText={<ErrorMessage name='fecha_fin' />}
                    error={errors.fecha_fin && touched.fecha_fin}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <AppField
                    type='date'
                    name='fecha_inicio_clases'
                    value={moment(values.fecha_inicio_clases).format(
                      'YYYY-MM-DD'
                    )}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label='Fecha de inicio de clases'
                    helperText={<ErrorMessage name='fecha_inicio_clases' />}
                    error={
                      errors.fecha_inicio_clases && touched.fecha_inicio_clases
                    }
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <AppField
                    type='date'
                    name='fecha_fin_clases'
                    value={moment(values.fecha_fin_clases).format('YYYY-MM-DD')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label='Fecha de fin de clases'
                    helperText={<ErrorMessage name='fecha_fin_clases' />}
                    error={errors.fecha_fin_clases && touched.fecha_fin_clases}
                  />
                </Grid>

                <Grid item sm={4}>
                  <SimpleSelect
                    style={{ minWidth: '160px' }}
                    id='ente_id'
                    name='ente_id'
                    value={values.ente_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label='Ente (*)'
                    items={entes}
                    helpertext={<ErrorMessage name='ente_id' />}
                    error={errors.ente_id && touched.ente_id}
                  />
                </Grid>

                <Grid item sm={12} md={2}>
                  <AppField
                    id='capacidad'
                    type='number'
                    name='capacidad'
                    value={values.capacidad}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label='capacidad'
                    helperText={<ErrorMessage name='capacidad' />}
                    error={errors.capacidad && touched.capacidad}
                  />
                </Grid>

                <Grid item sm={3}>
                  <Button onClick={handleClick}>categorias</Button>
                  <CheckBox
                    anchorEl={anchorEl}
                    checked={values.categorias}
                    handleToogle={(e) =>
                      handleToogle(
                        e,
                        setFieldValue,
                        values.categorias,
                        'categorias'
                      )
                    }
                    data={categorias}
                    onClose={handleClose}
                  />
                </Grid>

                <Grid item sm={3}>
                  <Button onClick={handleClickProfesores}>facilitadores</Button>
                  <CheckBox
                    anchorEl={anchorEls}
                    checked={values.facilitadores}
                    handleToogle={(e) =>
                      handleToogle(
                        e,
                        setFieldValue,
                        values.facilitadores,
                        'facilitadores'
                      )
                    }
                    data={stateFacilitadores}
                    onClose={handleClose}
                  />
                </Grid>

                {initialValues.creado_por ? (
                  <Grid item sm={6}>
                    <SimpleSelect
                      style={{ minWidth: '160px' }}
                      id='estatus'
                      name='estatus'
                      value={values.estatus}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label='Estatus'
                      items={status}
                      helpertext={<ErrorMessage name='estatus' />}
                      error={errors.ente_id && touched.ente_id}
                    />
                  </Grid>
                ) : null}

                <Grid container justifyContent='center'>
                  {isLoading ? (
                    <CircularProgress color='primary' />
                  ) : (
                    ActionsButtons
                  )}
                </Grid>
              </Grid>
            </form>
          )
        }}
      </Formik>
    </Fragment>
  );
}

export default React.memo(CursoFrom);
