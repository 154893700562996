// @Vendors
import React, { useState } from 'react'

// @Material ui
import {
	Grid,
	//Typography,
	Grow,
	Avatar
} from '@material-ui/core'

import Chip from '@material-ui/core/Chip';
// @Components
import Table from 'components/Table'

function UsersRolesList({ users, usersList, onDelete = () => console.log, currentRoles = [] }) {
	const [loading] = useState(false)

	const [filter, setFilter] = useState({
	    limit: 10,
	    title: "",
	    page: 0,
	    search: {},
	    rowsPerPage: 10,
	    order: 'desc',
	    orderBy: 'username',
	    selected: []
	})

	const columns = [
		{
			id: "usuario_id",
			label: "Usuario",
			render: (value) => {
				const currentUser = usersList.filter( e => e.id === value)
				const { name } = currentUser[0] || ""
				return <span>{ name } </span>
			}
		},
		{
			id:"roles",
			label: "Roles",
			render: (value, row) => (
				<div style={{ display: "flex", gap: "20px"}}>
					{value.length && value.map((rol_id, ind) => {
						const currentRol = currentRoles.length ? currentRoles.filter( r => r.id === rol_id)[0].name : "(Rol no definido)"
						return <Chip
								key={`${ind}-${currentRol}_$`}
								variant="outlined"
								color="secondary"
								size="medium"
								onDelete={onDelete({
									usuario_id: row.usuario_id,
									rol_id
								})}
								avatar={
									<Avatar>{currentRol.slice(0, 1).toUpperCase()}
									</Avatar>
								}
								label={currentRol} />
					})}
				</div>
			)
		}
	]

	return (
		<Grow in={true} timeout={1500}>
			<Grid container>
				
				<br/><br/>
				<Table
					isLoading={loading}
					fieldId="usuario_id"
					columns={columns}
					rows={users}
					count={users.length}
					order={filter.order}
					orderBy={filter.orderBy}
					page={filter.page}
					requestSort={ (order, orderBy) => {
						setFilter((prev) => ({
							...prev, order, orderBy
						}))
					}}
					rowsPerPage={filter.rowsPerPage}
					selected={filter.selected}
					setPage={ page => {
						setFilter( prev => ({
							...prev, page
						}))
					}}
					setRowsPerPage={ rowsPerPage => {
						setFilter( prev => ({
							...prev, rowsPerPage
						}))
					}}
					setSelected={ selected => {
						setFilter( prev => ({
							...prev, selected
						}))
					}}
				/>
			</Grid>
		</Grow>
	)
}

export default React.memo(UsersRolesList)