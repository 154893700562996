//@Vendors
import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,

  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,

} from "recharts";

//@material-ui
import { Typography, Paper } from "@material-ui/core";

// @Providers
import {
  
  getReportEstados,
} from "providers/getReportes.providers";

export default class Componente extends PureComponent {
  state = { data: [] };

  componentDidMount() {
    getReportEstados()
      .then((res) => {
        const { data } = res.data;
        const payload = data.map(({ nombre, cantidad }, index) => ({
          name: nombre,
          total: Number(cantidad),
        }));

        this.setState({ ...this.state, data: payload });
      })
      .catch((err) => {
        /* silenc */
      });
  }

  render() {
    return (
      <Paper>
        <Typography align="center" variant="h5" color="primary">
          Reportes
        </Typography>
        <br/><br/>
        <BarChart
          width={(window.innerWidth * 75) / 100}
          height={400}
          data={this.state.data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          
          <Bar dataKey="total" fill="#454452" />
        </BarChart>
      </Paper>
    );
  }
}
