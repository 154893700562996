// @Vendors
import React, { useEffect, useCallback } from 'react';
import { debounce } from 'lodash'

import { makeStyles } from '@material-ui/core/styles';

// @Material ui
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import Grow from '@material-ui/core/Grow';

// @Components
import CustomList from './CustomList'
import UsersRolesList from './UsersRolesList'

// @Hooks
import useUsuariosRoles from '../hooks/useUsuariosRoles'
import useNearScreen from 'hooks/useNearScreen'

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: "100%",
    height: 300,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function TransferList() {
  const classes = useStyles();
  const {
  	users,
  	roles,
  	usersChecked,
  	rolesChecked,
    handleCheckedRight,
    handleToggle,
    checked,
    handleToggleAll,
    numberOfChecked,
    usersRoles,
    removeRoleFromUser,
    handleSearchUsers,
    setPageUsers,
    loadingNextPage,
    getUsersAsigneds
   } = useUsuariosRoles()

  const { isNearScreen, fromRef } = useNearScreen({
    distance: "250px",
    once: false
  })

  const debounceHandleNextUsers = useCallback(debounce(
    () => setPageUsers(prevPage => prevPage + 1), 300
  ), [setPageUsers]);

  useEffect(function () {
    if (isNearScreen) debounceHandleNextUsers()
  }, [debounceHandleNextUsers, isNearScreen]);

  useEffect(() => {
    getUsersAsigneds()
  }, [getUsersAsigneds])

  return (
    <Grow in={true} timeout={1000}>
      <Grid container spacing={3} justify="center" alignItems="center">
        <Grid item sm>
        	<CustomList
            items={users}
            handleToggle={handleToggle}
            checked={checked}
            handleToggleAll={handleToggleAll}
            numberOfChecked={numberOfChecked}
            title={<Typography color="secondary">Lista de Usuarios </Typography>}
            observer={fromRef}
            observerLoading={loadingNextPage}
            onSearch={handleSearchUsers}
          />
        </Grid>
        
        <Grid item sm>
          <Grid container direction="column" alignItems="center">
            
            <Button
              startIcon={<CheckIcon />}
              variant="outlined"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={!rolesChecked.length || !usersChecked.length}
              aria-label="move selected right"
              size="medium"
              color="primary"
            >
              Asignar
            </Button>
          </Grid>

        </Grid>
        
        <Grid item sm>
        	<CustomList
            items={roles}
            handleToggle={handleToggle}
            checked={checked}
            handleToggleAll={handleToggleAll}
            numberOfChecked={numberOfChecked}
            title={<Typography color="secondary">Lista de Roles </Typography>}
          />
        </Grid>

        <Grid item sm={12}>
          <UsersRolesList
            usersList={users}
            users={usersRoles}
            onDelete={removeRoleFromUser}
            currentRoles={roles}
          />
        </Grid>
      </Grid>
     </Grow>
  );
}

export default (TransferList)
