export const linksMenu = [
  {
    title: 'Inicio',
    to: '',
  },
  {
    title: 'Oferta Formativa',
    to: 'oferta-formativa',
  },
  {
    title: 'Contacto',
    to: 'contacto',
  }
];
