// @Vendors
import { useEffect, useState } from 'react'

// @Services
import { getUsersService } from 'state/users/users.services'

const INITIAL_PAGE = 0
const LIMIT = 15

export function useUsuarios() {
  const [loading, setLoading] = useState(false)
  const [loadingNextPage, setLoadingNextPage] = useState(false)
  const [stopLoad, setStopLoad] = useState(false)

  const [page, setPageUsers] = useState(INITIAL_PAGE)

  const [users, setUsers] = useState([])

  useEffect( function () {
    setLoading(true)

    getUsersService({ limit: LIMIT, offset: (INITIAL_PAGE * LIMIT) }).then( res => {
        const { rows } = res.data.data
  
        const payload = rows.map( ({ id, username }) => ({ 
          id, name: username.toLowerCase()
        }))
        
        setUsers(payload)
        setLoading(false)
    } ).catch( err => console.log("ocurrió un error"))
  }, [])
  
  useEffect(function () {
    if (page === INITIAL_PAGE || loading) return
    if(stopLoad) return

    setLoadingNextPage(true)
    getUsersService({ limit: LIMIT, offset: (page * LIMIT) }).then( res => {
        const { rows } = res.data.data
  
        const payload = rows.map( ({ id, username }) => ({ 
          id, name: username.toLowerCase()
        }))

        setUsers( prev => {
          const newState = [...prev, ...payload]

          if(prev.length === newState.length) setStopLoad(true)
          return newState        
        })

        setLoadingNextPage(false)
    }).catch(err => console.log("Error al cargar mas usuarios"))
  }, [page, stopLoad, loading]);

  return { loading, loadingNextPage, users, setPageUsers };
}