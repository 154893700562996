// @MaterialUI
import { makeStyles } from '@material-ui/core/styles'

export default function useStyles () {

    const styles = makeStyles((theme) => ({
      paperContent: {
        padding: 20,
        background: "#fff",
        borderRadius:"5px"
      }
    }))

    const classes = styles()

    return {
        classes
    }
}