// @Vendors
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { upperFirst } from 'lodash'


import {
  GET_CATEGORIAS, UPDATE_FILTERS, UPDATE_CATEGORIAS, CREAR_CATEGORIAS, DELETE_CATEGORIAS
} from "state/categorias/categorias.actionsTypes";

// @Components
import Table from "components/Table";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Typography, Paper } from "@material-ui/core";
import ActionsButtons from "components/ActionsButtons";
import CategoriasForm from "./components/categoriasForm";

// @Icons
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

// @Actions
import {
  openModal,
  openDialogConfirm,

} from "state/app/app.actions";

// @Styles
import useStyles from "../usuarios/useStyles";

function Index() {
  const dispatch = useDispatch();
  const categorias = useSelector((state) => state.categoriasReducer.get("categorias"));
  var isLoading = useSelector((state) => state.appReducer.get("loading"));
  const filters = useSelector((state) => state.categoriasReducer.get("filters"));
  const { usuario_id } = useSelector((state) =>
    state.usersReducer.get("profiles")
  );

  const { classes } = useStyles();

  const { rows, count } = categorias;
  const { order, orderBy, page, rowsPerPage, selected } = filters;

  useEffect(() => {
    dispatch({
      type: GET_CATEGORIAS,
      payload: {
        limit: count
        // creado_por: usuario_id,
      },
    });
  }, [dispatch, usuario_id, count]);

  const eliminarCategoria = (id) => {
    dispatch(
      openDialogConfirm({
        title: 'Eliminar Categoria',
        description:
          'Esta seguro que deseas eliminar esta categoria esta acción sera permanente',
        onConfirm: () => {
          dispatch({ type: DELETE_CATEGORIAS, payload: { categorias, id } });
        },
      })
    );
  };

  const columns = [
    {
      id: "nombre",
      numeric: false,
      disablePadding: true,
      label: "Nombre",
      rowProps: {
        component: "th",
        scope: "row",

      },
      render: (nombre) =>   upperFirst(nombre.toLowerCase()) 
    },
    {
      id: "acciones",
      numeric: false,
      disablePadding: true,
      label: null,
      rowProps: {
        component: "th",
        scope: "row",
        align: "right",
      },
      render: (value, row) => {
        return (
          <div>
            <Tooltip title="Editar">
              <IconButton
                aria-label="Editar"
                onClick={() => {
                  actionCategorias(row);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar">
              <IconButton
                aria-label="Eliminar"
                onClick={() => {
                  eliminarCategoria(row.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];



  const actionCategorias = ({
    id,
    nombre, version, actualizado_por

  } = {}) => {
    const initialValues = nombre
      ? {
        id,
        nombre, version, actualizado_por
      }
      : undefined;

    dispatch(
      openModal(
        <div style={{ display: "grid", gap: "30px" }}>
          <Typography align="center" variant="h5" color="primary">
            {initialValues ? "Actualizar categoria" : "Crear categoria"}
          </Typography>
          <CategoriasForm
            initialValues={initialValues}
            onSubmit={(payload) => {
              initialValues
                ? dispatch({ type: UPDATE_CATEGORIAS, payload })
                : dispatch({ type: CREAR_CATEGORIAS, payload });
            }}
            ActionsButtons={
              <ActionsButtons texto="agregar" disableButton={isLoading} />
            }
          />
        </div>
      )
    );
  };

  return (
    <Fragment>
      <Paper className={classes.paperContent}>
        <Typography align="center" variant="h5" color="primary">
          Administrar categorias
        </Typography>
        <div style={{ textAlign: "center" }}>
          {!isLoading && (
            <Tooltip title="Actualizar tabla">
              <IconButton
                onClick={() =>
                  dispatch({
                    type: GET_CATEGORIAS,
                    payload: {
                      limit: count
                      // creado_por: usuario_id,
                    },
                  })
                }
              >
                <RefreshIcon style={{ color: "#777" }} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Crear nueva categoria">
            <IconButton
              onClick={() => {
                actionCategorias();
              }}
            >
              <AddCircleOutlineIcon style={{ color: "#777" }} />
            </IconButton>
          </Tooltip>
        </div>

        <Table
          isLoading={isLoading}
          fieldId="id"
          columns={columns}
          rows={rows}
          count={count}
          order={order}
          orderBy={orderBy}
          page={page}
          requestSort={(order, orderBy) => {
            dispatch({
              type: UPDATE_FILTERS,
              payload: { ...filters, order, orderBy },
            });
          }}
          rowsPerPage={rowsPerPage}
          selected={selected}
          setPage={(page) => {
            dispatch({
              type: UPDATE_FILTERS,
              payload: { ...filters, page },
            });
          }}
          setRowsPerPage={(rowsPerPage) => {
            dispatch({
              type: UPDATE_FILTERS,
              payload: { ...filters, rowsPerPage },
            });
          }}
          setSelected={(selected) => {
            dispatch({
              type: UPDATE_FILTERS,
              payload: { ...filters, selected },
            });
          }}
        />
      </Paper>
    </Fragment>
  );
}

export default Index;
