//  @Vendors
import React from 'react'

// @Material UI
import {
    Typography,
    Grow
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';

// @Icons
import EditIcon from '@material-ui/icons/Edit';

// @Components
import CrearCuenta from 'pages/RegisterUser/forms/CrearCuenta'
import ActionsButtons from 'components/ActionsButtons'
import {
	CircularProgress 
} from '@material-ui/core'

function UpdateUser({ row, onSubmit, isLoading }) {
	
	if(!row && !row.length) return (
		<p style={{textAlign:"center"}}>
			<CircularProgress/>
		</p>
	)

    const {
      username,
      pregunta_seguridad_id,
      respuesta_seguridad,
    } = row

	return (
		<Grow in={true} timeout={1000}>
			<div style={{ display: "grid", "gap":"40px"}}>
				<Typography variant="h4" color="primary" align="center">
					<EditIcon/> Actualizar datos
				</Typography>

				<Alert severity="info">
					Actualizar el usuario <strong>{ username.toLowerCase() }</strong>
				</Alert>
				<br/>

				<CrearCuenta
					initialValues={{
				      	respuesta_seguridad,
				      	pregunta_seguridad_id: pregunta_seguridad_id.toString(),
						username: username.toLowerCase(),
						password: "",
						passwordRepeat: ""
					}}
					onSubmit={onSubmit}
					ActionsButtons={
						<ActionsButtons texto="Actualizar" actualVisible={0} totalForms={0} disabledButton={isLoading}/>
					}/>
			</div>
		</Grow>
	)
}

export default React.memo(UpdateUser)
