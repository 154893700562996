// @Vendors
import React, { useEffect } from "react"

//@Material-ui
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"

//@hooks
import useWindowWidth from "hooks/useWindowWidth"
import LogoMujer from "components/LogoMujer"

//@Components
import Mobile from "./Mobile"
import Desktop from "./Desktop"
import ControlBtnLogin from './ControlBtnLogin'

function MenuBar({
  logout,
  user,
  drawerWidth,
  linksMenu,
  ...props
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      position: "fixed",
      background: "#fff",
      boxShadow: "0px 3px 8px -2px rgba(0,0,0,0.2)",
      top: "0",
      "& > .drawer--toolbar" : {
        transition: theme.transitions.create('padding', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        paddingLeft: drawerWidth
      }
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      color: "#fff",
    },
    menuItems: {
      width: "auto",
      display: "grid",
      gridAutoColumns: "max-content",
      gridAutoFlow: "column",
      alignItems: "center",
      gap: "15px",
      "&  a": {
        color: "#000",
      },
    }
  }))

  const classes = useStyles();
  const [showMenuRes, setShowMenuRes] = React.useState(false);
  const { width } = useWindowWidth();

  useEffect(() => {
    if (width > 0 && width < 900) {
      setShowMenuRes(true);
    } else {
      setShowMenuRes(false);
    }
  }, [width]);

  return (  
    <AppBar position="sticky" {...props} className={classes.root}>
      <Toolbar className="drawer--toolbar">
        <LogoMujer className="boxLogo" />
        <div className={classes.title} />

        {showMenuRes ? (
          <Mobile
          links={linksMenu}
          BtnPublic={<ControlBtnLogin logout={logout} auth={user.isAuthenticated}/>} />
        ) : (
          <Desktop
            links={linksMenu}
            BtnPublic={<ControlBtnLogin logout={logout} auth={user.isAuthenticated}/>}
          />
        )}
      </Toolbar>
    </AppBar>
  )
}

export default React.memo(MenuBar);
