//@Vendors
import React from "react";
import {makeStyles} from '@material-ui/core/styles'

//@Material-UI
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";
import LanguageIcon from '@material-ui/icons/Language';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

const styles = makeStyles((theme)=>({
  socialContainer:{
    display: 'flex',
    alingItems: 'center',
    justifyContent: 'space-beteewn',
    margin:'0 25px'
  },
  links: {
    color:"#000",
    margin: '0 3px',
    cursor: 'poniter',
    '& :hover':{
        color: '#662483',
    },
  },
}))

const socialBar = (props) => {
  const classes = styles()
  return (
    <div {...props}>
       <a href="https://www.mincyt.gob.ve" className={classes.links}>
        <LanguageIcon  />
      </a>
      <a href="https://www.instagram.com/mincyt.ve/" className={classes.links}>
        <InstagramIcon  />
      </a>
      <a href="https://www.youtube.com/@mincytvenezuela" className={classes.links}>
        <YouTubeIcon  />
      </a>
      <a href="https://twitter.com/Mincyt_VE" className={classes.links}>
        <TwitterIcon />
      </a>
      <a href="mailto://mujerti@mppct.gob.ve" className={classes.links}>
        <AlternateEmailIcon />
      </a>
    </div>
  );
};

export default socialBar;
