import React from 'react'
import { upperFirst } from 'lodash';
import {Menu, MenuItem, Checkbox, ListItemIcon , ListItemText } from '@material-ui/core'


function CheckBox({anchorEl, onClose, data, checked, handleToogle}) {
 
    return (
       <Menu
       anchorEl={anchorEl}
       open={Boolean(anchorEl)}
        onClose={onClose}
        PaperProps={{
            style: {
              maxHeight: 50 * 4.5,
              width: '40ch',
            },
          }}
       >
       {data.map(({label, value}) =>{
 
           return (
               <MenuItem key={value}
               button
               onClick={() => {
                handleToogle(value);
              }}
               >
                   <ListItemIcon>
                   <Checkbox
                    edge='start'
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby':label }}
                  />
                   <ListItemText id={value} primary={upperFirst(label)} />
                  </ListItemIcon>
               
               </MenuItem>
           )
       })}
       </Menu>
    )
}

export default CheckBox
