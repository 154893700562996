// @Vendors
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// @Material ui
import {
	Grow,
	Grid,
	Typography
} from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles"

// @ActionsTypes
import { LOGIN } from "state/auth/auth.actionsTypes"

// @Components
import AuthForm from "pages/Login/AuthForm"

const useStyles = makeStyles((theme) => ({
  paperBox: {
  	margin: "3pc auto",
    padding: "25px",
    boxSizing: "border-box",
    background: "#f9f9f9",
    borderRadius: "10px",
    boxShadow: "0 5px 10px 0 #c3b8b8",
    display: "grid",
    gap: "30px"
  }
}))

export default function AdminLogin() {
	const dispatch = useDispatch()
	const classes = useStyles()
	const loading = useSelector((state) => state.authReducer.get("loading"))

	const submit = React.useCallback( (values) => {
      dispatch({ type: LOGIN, payload: values })
    }, [dispatch])

	return (
		<Grow in={true} timeout={1000}>
			<Grid container justify="center">
				<div className={classes.paperBox}>
				<Typography align="center" color="primary" variant="h5">
					Iniciar sesión (Administrador)
				</Typography>
					<AuthForm 
						onSubmit={submit}
						disabledButton={loading}
					/>	
				</div>	
			</Grid>	
		</Grow>
	)
}