import React from "react"
import { NavLink } from "react-router-dom"

//@Material-ui
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    color: "#fff",
  },
  menuItems: {
    width: "auto",
    display: "grid",
    gridAutoColumns: "max-content",
    gridAutoFlow: "column",
    alignItems: "center",
    gap: "15px",
    [theme.breakpoints.down("sm")]: {
      gap: '0',
    },  
    "&  a": {
      color: "#000",
    },
  },
  item: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    display: "grid",
    placeItems: "center",
    padding: "10px",
    boxSizing: "border-box",
    "&  p": {
      margin: "0",
    },
    "&:hover": {
      color: "#652385",
      position: "relative",
    },
    "&:hover::before": {
      position: "absolute",
      content: '""',
      bottom: "5%",
      height: "6px",
      width: "6px",
      background: theme.palette.primary.main,
      left: "0",
      right: "0",
      margin: " 0 auto",
      borderRadius: "5px",
    },
    "&.active": {
      color: "#652385",
      position: "relative",
    },
    "&.active::before": {
      position: "absolute",
      content: '""',
      bottom: "5%",
      height: "6px",
      width: "6px",
      background: theme.palette.primary.main,
      left: "0",
      right: "0",
      margin: " 0 auto",
      borderRadius: "5px",
    },
  },
  btnLogin: {
    textDecoration: "none",
    "& button": {
      borderRadius: "60px",
    },
  },
}));

const Desktop = ({ BtnPublic = "", links, classe = ""}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.menuItems} ${classe}`}>
      {links.map(({ title, to, canActive = false }, index) =>  (
        canActive
        ? ""
        : <NavLink key={index} exact to={`/${to}`} className={classes.item}>
            <Typography color="inherit" variant="body1" gutterBottom>
              {title}
            </Typography>
          </NavLink>
        )
     )}

      {BtnPublic}
    </div>
  );
};

export default Desktop;
