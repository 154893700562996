// @Vendors				
import React, { memo, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { find } from 'lodash'

// @Material ui
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';
import { ListItemLoading } from './ListItemLoading'
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import PageviewIcon from '@material-ui/icons/Pageview';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    minWidth: "280px",
    height: 350,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  }
}));

function CustomList({ 
	title,
	items,
	handleToggle = () => () => console.log,
	checked,
	handleToggleAll,
	numberOfChecked,
	observer = false,
	observerLoading = false,
	onSearch
}) {
	const classes = useStyles();
	const { username } = useSelector(state => state.usersReducer.get("user"))
	return (
		<Card>
			{onSearch && 
		  	<Fragment>
				<CardHeader
					className={classes.cardHeader}
					subheader={
						<TextField
							disabled={!items.length}
							id="outlined-basic"
							variant="outlined"
							label="Buscar..."
							onKeyUp={onSearch}
							InputProps={{
								endAdornment:<InputAdornment position="end">
								<PageviewIcon/>
								</InputAdornment>,
					        }}
						/>
					}
				/>
		  		<Divider />
		  	</Fragment>
			}
		  <CardHeader
		    className={classes.cardHeader}
		    avatar={
		      <Checkbox
		        onClick={handleToggleAll(items)}
		        checked={numberOfChecked(items) === items.length && items.length !== 0}
		        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
		        disabled={items.length === 0}
		        inputProps={{ 'aria-label': 'all items selected' }}
		      />
		    }
		    title={title}
		    subheader={`${numberOfChecked(items)}/${items.length} seleccionado(s)`}
		  />
		  <Divider />
		  <List className={classes.list} dense component="div" role="list">
		    {items.length ? items.map( (value) => {
		      const labelId = `transfer-list-all-item-${value}-label`;

		      return (
		        <ListItem 
		        	key={`${value.id}`}
		        	role="listitem"
		        	button
		        	onClick={handleToggle(value)}>
					<ListItemIcon>
						<Checkbox
						  checked={find(checked, { name: value.name }) !== undefined}
						  tabIndex={-1}
						  disableRipple
						  inputProps={{ 'aria-labelledby': labelId }}
						/>
					</ListItemIcon>
					<ListItemText id={labelId} primary={
						<span style={{ fontWeight: value.name === username.toLowerCase() && "bold" }}>
							{ value.name } 
						</span>}
					/>
		        </ListItem>
		      );
		    }) : ListItemLoading(10) }
		    <ListItem />
		  {observer && 
		  	<div ref={observer}>
		  		{ observerLoading && ListItemLoading(10) }
	    	</div>}
		  </List>
		</Card>
	);
}

export default memo(CustomList)