// @Vendors
import { useState, useCallback } from 'react'
import { remove, intersectionWith, debounce } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

// Hooks
import { useUsuarios } from './useUsuarios'
import { useRoles } from './useRoles'

// @ActionsTypes
import {
	ASSIGN_ROLE,
	REVOQUE_ROLE,
	GET_USERS_ASIGNED
} from 'state/users/users.actionsTypes'

// @Helpers
import { not, union } from 'utils/helpers'

export default function useUsuariosRoles() {
	const { users, setPageUsers, loadingNextPage } = useUsuarios()
	const { roles } = useRoles()
	const dispatch = useDispatch()
	
	const [checked, setChecked] = useState([]);
	const usersRoles = useSelector( state => state['usersReducer'].get("asignaciones"))

	const usersChecked = intersectionWith(checked, users);
	const rolesChecked = intersectionWith(checked, roles);

	const numberOfChecked = (items) => intersectionWith(checked, items).length;

	const handleToggle = (value) => () => {
		const currentValue = intersectionWith(checked, [value])		
		const newChecked = [...checked]
		
		if(!currentValue.length) {
			newChecked.push(value)
		} else {
			remove(newChecked, (v) => v.name.toLowerCase() === value.name.toLowerCase())
		}

		setChecked(newChecked)
	};

	const handleToggleAll = (items) => () => {
		if (numberOfChecked(items) === items.length) {
		  setChecked(not(checked, items));
		} else {
		  setChecked(union(checked, items));
		}
	};

	const handleCheckedRight = () => {
		const roles = rolesChecked.reduce((acc, el) => acc.concat(el.id), [])

		const payload = usersChecked.reduce( (acc, el) => {
			return acc.concat([{ usuario_id: el.id, roles }])
		}, [])

		// disparar accion al API
		dispatch({ type: ASSIGN_ROLE, payload })
		setChecked([])
	};

	const removeRoleFromUser = (payload) => () => {
		dispatch({ type: REVOQUE_ROLE, payload })
	}

	const debounceHandleSearch = useCallback(debounce(
		(value) => console.log(value), 300
	), []);

	function handleSearchUsers({ target }) {
		debounceHandleSearch(target.value)
	}

	function getUsersAsigneds(){
		dispatch({ type: GET_USERS_ASIGNED })
	}

	return {
		users,
		roles,
		checked,
		usersChecked,
		rolesChecked,
		usersRoles,
		// **functions
		handleCheckedRight,
		handleToggleAll,
		numberOfChecked,
		handleToggle,
		removeRoleFromUser,
		handleSearchUsers,
		setPageUsers,
		loadingNextPage,
		getUsersAsigneds
	};
}