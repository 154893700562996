import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

export function ListItemLoading(repeat = 10) {
	let loadingList = []
	
	for (let i = 1; i < repeat; i++) { loadingList.push(
		<ListItem 
			role="listitem"
			key={`loading_${i}$`}
		>
			<ListItemIcon>
				<Skeleton width={20} height={30}/>
			</ListItemIcon>
			
			<ListItemText
				id={`loading_${i}`}
				primary={<Skeleton width={230} height={30}/>}
			/>
		</ListItem>	
	)}
	return loadingList
}