import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// @Components
import AppField from 'components/Form/AppField';
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';

const initial = {
  nombre: '',
};

function CategoriasForm({
  initialValues = initial,
  onSubmit,
  ActionsButtons = '',
  cargando,
}) {
  const onlyLetters = RegExp('^[a-zA-ZÁÉÍÓÚáéíóúñÑ0-9 ]+$');
  const categoriaSchema = Yup.object().shape({
    nombre: Yup.string()
      .required('¡Campo obligatorio!')
      .matches(onlyLetters, 'valor fuera de rango'),
  });

  var isLoading = useSelector((state) => state.appReducer.get('loading'));
  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={categoriaSchema}>
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AppField
                  name='nombre'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nombre}
                  label='Nombre de la categoria (*)'
                  autoFocus={true}
                  helperText={<ErrorMessage name='nombre' />}
                  error={errors.nombre && touched.nombre}
                />
              </Grid>

              <Grid container justify='center'>
                {isLoading ? (
                  <CircularProgress color='primary' />
                ) : (
                  ActionsButtons
                )}
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Fragment>
  );
}

export default CategoriasForm;
