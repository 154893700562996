import React from "react";
import { NavLink } from "react-router-dom";

import clsx from "clsx";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: "#000",
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  menu: {
    color: "#000",
  },
  item: {
    textDecoration: "none",
    color: "#000",
    display: "grid",
    placeItems: "center",
    padding: "10px",
    boxSizing: "border-box",
    "&  p": {
      margin: "0",
    },
    "&:hover": {
      color: "#652385",
      position: "relative",
    },
    "&:hover::before": {
      position: "absolute",
      content: '""',
      bottom: "5%",
      height: "6px",
      width: "6px",
      background: theme.palette.primary.main,
      left: "0",
      right: "0",
      margin: " 0 auto",
      borderRadius: "5px",
    },
    "&.active": {
      color: "#652385",
      position: "relative",
    },
    "&.active::before": {
      position: "absolute",
      content: '""',
      bottom: "5%",
      height: "6px",
      width: "6px",
      background: theme.palette.primary.main,
      left: "0",
      right: "0",
      margin: " 0 auto",
      borderRadius: "5px",
    },
  },
}));

export default function Mobile({ BtnPublic = "", links }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerOpen}
          anchor="right"
          className={clsx(open && classes.hide)}
        >
          <MenuIcon className={classes.menu} />
        </IconButton>
      </Toolbar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>

        <List>
          {links.map(({ title, to, canActive = false }, index) =>
            canActive ? (
              ""
            ) : (
              <ListItem button key={index}>
                <NavLink to={`/${to}`} className={classes.item}>
                  {/* <HomeIcon /> */}
                  <Typography color="inherit" variant="body1" gutterBottom>
                    {title}
                  </Typography>
                </NavLink>
              </ListItem>
            )
          )}
        </List>
        <Divider />
        {BtnPublic}
      </Drawer>
    </div>
  );
}
