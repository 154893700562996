// @Vendors
import React, { useEffect, memo } from 'react'

// @Redux
import { useSelector, useDispatch } from 'react-redux'

// @ActionsTypes
import { GET_USERS, UPDATE_FILTERS, DELETE_USER, UPDATE_USER } from 'state/users/users.actionsTypes';
import { openModal, openDialogConfirm } from 'state/app/app.actions'

// @Material UI
import {
    Typography,
    Paper,
    IconButton,
    Tooltip
} from '@material-ui/core'

// @Icons
import RefreshIcon from "@material-ui/icons/Refresh";

// @Views components
import ViewUser from './components/viewUser'
import UpdateUser from './components/updateUser'

// @Hooks
import useStyles from './useStyles'

function Usuarios() {
  const { classes } = useStyles()

  const dispatch = useDispatch();
  const users = useSelector(state => state.usersReducer.get('users'));
  const filters = useSelector( state => state.usersReducer.get("filters"));
  const isLoading = useSelector(state => state.appReducer.get('loading'));

  function onUpdate(row) {

    const onSubmitEdit = (data) => {
      const { id, version } = row
      delete data.passwordRepeat
      
      const payload = { id, version, ...data }

      return dispatch({ type: UPDATE_USER, payload })
    }

    dispatch(openModal(<UpdateUser onSubmit={onSubmitEdit} row={row} isLoading={isLoading}/>))
  }

  function onDelete(id, version) {
    dispatch(openDialogConfirm({
      title:"Eliminar usuario",
      description: "¿Seguro de Eliminar este registro?",
      onConfirm: () => dispatch({ type: DELETE_USER, payload: { id, version, users } })
    }))
  }

  useEffect(() => {
    dispatch({ type: GET_USERS });
  }, [dispatch]);


  return (
    <Paper className={classes.paperContent}>
      <Typography variant="h5" color="primary" align="center">
      	Administrar usuarios
      </Typography>

      <IconButton onClick={() => dispatch({ type: GET_USERS })}>
        <Tooltip title="Recargar tabla">
          <RefreshIcon />
        </Tooltip>
      </IconButton>

      <br/>

      <ViewUser
        users={users}
        filters={filters}
        actionFilters={UPDATE_FILTERS}
        dispatch={dispatch}
        onUpdate={onUpdate}
        onDelete={onDelete}
        isLoading={isLoading}
      />
      
    </Paper>
  )
}

export default memo(Usuarios)