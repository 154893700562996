//  @Vendors
import React from 'react'
import { capitalize } from 'lodash'

// @Components
import Table from 'components/Table'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

// @Icons
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

function ViewUser({ filters, users, isLoading, dispatch, actionFilter, onUpdate, onDelete}) {
	const {
		rows,
  		count
	} = users
	const {
	  	order,
	  	orderBy,
	  	page,
	  	rowsPerPage,
	  	selected,
  	} = filters

  const columns = [
		{
			id: 'cedula',
			numeric: false,
			disablePadding: false,
			label: 'Cédula',
			rowProps: {
				component: 'th', scope: 'row'
			},
			render: (value, row) => {
				const { usuarios_cedulado } = row

				if(usuarios_cedulado){
					const { nacionalidad, cedula } = usuarios_cedulado
					return `${nacionalidad}-${cedula}`
				} else {
					return <i style={{ color: "#77428e" }}>(No cedulado)</i>
				}
			}
		},
		{
			id: 'username',
			numeric: false,
			disablePadding: false,
			label: 'Usuario',
			rowProps: {
				component: 'th', scope: 'row'
			},
			render: (value) => {
				return <span>{ value.toLowerCase() }</span>
			}
		},
		{
			id: 'pregunta_seguridad',
			numeric: false,
			disablePadding: false,
			label: 'Pregunta de seguridad',
			rowProps: {
				component: 'th', scope: 'row'
			},
			render: (value, { pregunta_seguridad }) => {
				return <span>{capitalize(pregunta_seguridad.nombre)}</span>
			}
		},
		{
			id : 'acciones',
			numeric: false,
			disablePadding: true,
			label: null,
			rowProps: {
				component: 'th', scope: 'row',
				align: 'right'
			},
			render: (value, row) => {
				const { id, version } = row
				return (
					<div>
						<Tooltip title="Editar">
							<IconButton aria-label="Editar" onClick={() => onUpdate(row)} >
								<EditIcon/>
							</IconButton>
						</Tooltip>
						<Tooltip title="Eliminar">
							<IconButton aria-label="Eliminar" onClick={() => onDelete(id, version)} >
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</div>
				)
			}
		}
	]

	return (
		<Table
			isLoading={isLoading}
			fieldId="id"
			columns={columns}
			rows={rows}
			count={count}
			order={order}
			orderBy={orderBy}
			page={page}
			requestSort={ (order, orderBy) => {
				dispatch({ 
					type: actionFilter,
					payload: { ...filters, order, orderBy }
				})
			}}
			rowsPerPage={rowsPerPage}
			selected={selected}
			setPage={ page => {
				dispatch({ 
					type: actionFilter,
					payload: { ...filters, page }
				})
			}}
			setRowsPerPage={ rowsPerPage => {
				dispatch({ 
					type: actionFilter,
					payload: { ...filters, rowsPerPage }
				})
			}}
			setSelected={ selected => {
				dispatch({ 
					type: actionFilter,
					payload: { ...filters, selected }
				})
			}}
		/>
	)
}

export default React.memo(ViewUser)