// @Vendors
import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, Redirect, useHistory } from 'react-router-dom'
import jsonwebtoken from 'jsonwebtoken'

// @Material ui
import { Typography, CircularProgress } from '@material-ui/core'

// @Services
import { activateUserService } from 'state/users/users.services'

// @ActionsTYpes
import {
    REQUEST_SUCCESS,
    REQUEST_FAILURE
} from 'state/app/app.actionTypes'

export default function Index() {
    const dispatch = useDispatch()
    const { push } = useHistory()
    const { token } = useParams()
    const [isActive, setIsActive] = useState(false)

    const activate = useCallback( async () => {
        try {

            const decodeToken = jsonwebtoken.decode(token)
            const { username, id } = decodeToken

            const res = await activateUserService({ username, id })
            const { data } = res
            if(data.success) {
                dispatch({ type: REQUEST_SUCCESS, payload: data.message })
                setIsActive(true)
            } else {
                dispatch({
                    type: REQUEST_FAILURE,
                    payload: { 
                        serverErrors: data.message
                    }
                })
                setTimeout(() => push("/iniciar-sesion"), 200)
            }

        } catch(error) {
            // Silenc
            //console.log(error)
            
            dispatch({
                type: REQUEST_FAILURE,
                payload: { 
                    serverErrors: "Ocurrió un error al verificar usuario"
                }
            })

            setTimeout(() => push("/iniciar-sesion"), 200)
        }
    }, [token, dispatch, push])


    useEffect(() => {
        activate()
    }, [activate])

    return <div style={{margin: "40px 0 0 0"}}>
        <Typography variant="h5" color="primary" align="center">
            Consultando
        </Typography>
        <p style={{margin: "20px auto", textAlign:"center"}}>
            { isActive ? <Redirect  to="/iniciar-sesion"/> : <CircularProgress /> }
            </p>
    </div>
}
