// @Vendors
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { upperFirst } from 'lodash';
import moment from 'moment';
import 'moment/locale/es';

// @ActionsTypes
import {
  GET_CURSOS,
  UPDATE_FILTERS,
  CREATE_CURSO,
  UPDATE_CURSO,
  DELETE_CURSO,
} from 'state/cursos/cursos.actionsTypes';
import { status } from './componentes/CursoForm';

// @Components
import Table from 'components/Table';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { Typography, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CursoForm from './componentes/CursoForm';

// @Icons
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

// @Actions
import { openModal, openDialogConfirm } from 'state/app/app.actions';
import { arrayChecked } from 'utils/helpers';

// @Styles
import useStyles from '../usuarios/useStyles';

function Index() {
  const dispatch = useDispatch();
  const cursos = useSelector((state) => state.cursosReducer.get('cursos'));
  var isLoading = useSelector((state) => state.appReducer.get('loading'));
  const filters = useSelector((state) => state.cursosReducer.get('filters'));
  const { usuario_id } = useSelector((state) =>
    state.usersReducer.get('profiles')
  );
  const { Roles: roles } = useSelector((state) =>
    state.usersReducer.get('user')
  );

  const { classes } = useStyles();

  const { rows, count } = cursos;
  const { order, orderBy, page, rowsPerPage, selected } = filters;

  useEffect(() => {
    if (roles) {
      const adminRoot = roles.some((rol) => rol.rol_id === 1);
      dispatch({
        type: GET_CURSOS,
        payload: adminRoot
          ? {}
          : {
              creado_por: usuario_id,
            },
      });
    } else {
      dispatch({
        type: GET_CURSOS
      })
    }
  }, [dispatch, usuario_id, roles]);

  const columns = [
    {
      id: 'nombre',
      numeric: false,
      disablePadding: false,
      label: 'Nombre',
      rowProps: {
        component: 'th',
        scope: 'row',
      },
    },
    {
      id: 'fecha_inicio',
      numeric: false,
      disablePadding: false,
      label: 'Inicio de preinscripciones',
      rowProps: {
        component: 'th',
        scope: 'row',
      },
      render: (value) => moment(value).format('ll'),
    },
    {
      id: 'fecha_fin',
      numeric: false,
      disablePadding: false,
      label: 'Fin de preinscripciones',
      rowProps: {
        component: 'th',
        scope: 'row',
      },
      render: (value) => moment(value).format('ll'),
    },
    {
      id: 'estatus',
      numeric: false,
      disablePadding: false,
      label: 'Estatus',
      rowProps: {
        component: 'th',
        scope: 'row',
      },
      render: (value) => {
        const actual = status.filter((state) => state.value === value);
        const { label } = actual[0];
        const color = label === 'activo' ? 'green' : 'red';

        return <i style={{ color }}> ({upperFirst(label.toLowerCase())}) </i>;
      },
    },
    {
      id: 'acciones',
      numeric: false,
      disablePadding: true,
      label: null,
      rowProps: {
        component: 'th',
        scope: 'row',
        align: 'right',
      },
      render: (value, row) => {
        return (
          <div>
            <Tooltip title='Editar'>
              <IconButton
                aria-label='Editar'
                onClick={() => {
                  actionCursos(row);
                }}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Eliminar'>
              <IconButton
                aria-label='Eliminar'
                onClick={() => {
                  eliminarCurso(row.id);
                }}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const eliminarCurso = (id) => {
    dispatch(
      openDialogConfirm({
        title: 'Eliminar Curso',
        description:
          'Esta seguro que deseas eliminar esta oferta formativa esta acción sera permanente',
        onConfirm: () => {
          dispatch({ type: DELETE_CURSO, payload: { cursos, id } });
        },
      })
    );
  };

  const actionCursos = ({
    id,
    nombre,
    descripcion,
    fecha_inicio,
    fecha_fin,
    fecha_inicio_clases,
    fecha_fin_clases,
    imagen,
    ente_id,
    capacidad,
    creado_por,
    version,
    estatus,
    Facilitadores,
    Categorias,
  } = {}) => {
    if (nombre) {
      var categorias = arrayChecked(Categorias, true);
      var facilitadores = arrayChecked(Facilitadores, false);
    }
    const initialValues = nombre
      ? {
          id,
          nombre,
          descripcion,
          fecha_inicio,
          fecha_fin,
          fecha_inicio_clases,
          fecha_fin_clases,
          imagen,
          capacidad,
          creado_por,
          version,
          estatus,
          ente_id: ente_id.toString(),
          facilitadores,
          categorias,
        }
      : undefined;

    dispatch(
      openModal(
        <div style={{ display: 'grid', gap: '30px' }}>
          <Typography align='center' variant='h5' color='primary'>
            {initialValues ? 'Actualizar Oferta formativa' : 'Crear Oferta formativa'}
          </Typography>

          <CursoForm
            initialValues={initialValues}
            onSubmit={(payload) => {
              initialValues
                ? dispatch({ type: UPDATE_CURSO, payload })
                : dispatch({ type: CREATE_CURSO, payload });
              dispatch({
                type: GET_CURSOS,
                payload: {
                  // creado_por: usuario_id,
                },
              });
            }}
            ActionsButtons={
            <Button 
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {!initialValues ? 'registrar' : 'actualizar'}
            </Button>
            }
          />
        </div>
      )
    );
  };

  return (
    <Fragment>
      <Paper className={classes.paperContent}>
        <Typography align='center' variant='h5' color='primary'>
          Administrar Oferta Formativa
        </Typography>
        <div style={{ textAlign: 'center' }}>
          {!isLoading && (
            <Tooltip title='Actualizar tabla'>
              <IconButton
                onClick={() =>
                  dispatch({
                    type: GET_CURSOS,
                    payload: {
                      // creado_por: usuario_id,
                    },
                  })
                }>
                <RefreshIcon style={{ color: '#777' }} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title='Crear nuevo Curso'>
            <IconButton
              onClick={() => {
                actionCursos();
              }}>
              <AddCircleOutlineIcon style={{ color: '#777' }} />
            </IconButton>
          </Tooltip>
        </div>

        <Table
          isLoading={isLoading}
          fieldId='id'
          columns={columns}
          rows={rows}
          count={count}
          order={order}
          orderBy={orderBy}
          page={page}
          requestSort={(order, orderBy) => {
            dispatch({
              type: UPDATE_FILTERS,
              payload: { ...filters, order, orderBy },
            });
          }}
          rowsPerPage={rowsPerPage}
          selected={selected}
          setPage={(page) => {
            dispatch({
              type: UPDATE_FILTERS,
              payload: { ...filters, page },
            });
          }}
          setRowsPerPage={(rowsPerPage) => {
            dispatch({
              type: UPDATE_FILTERS,
              payload: { ...filters, rowsPerPage },
            });
          }}
          setSelected={(selected) => {
            dispatch({
              type: UPDATE_FILTERS,
              payload: { ...filters, selected },
            });
          }}
        />
      </Paper>
    </Fragment>
  );
}

export default Index;
