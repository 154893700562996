// @Vendors
import { useEffect, useState } from 'react'
import { upperFirst } from 'lodash'

// @Services
import { getRolesServices } from 'providers/getRoles.services'

export function useRoles() {
  const [loading, setLoading] = useState(false)
  const [roles, setRoles] = useState([])

  useEffect( function () {
    setLoading(true)

    getRolesServices().then( res => {
        const { rows } = res.data.data

        const payload = rows.map( ({ id, nombre }) => ({ 
          id, name: upperFirst(nombre.toLowerCase())
        }))
        
        setRoles(payload)
        setLoading(false)
    } ).catch( err => console.log("ocurrió un error"))
  }, [])

  return { loading, roles };
}